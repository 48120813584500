<template>
  <div
    v-if="showFinancingAvailable && totalAmountMeetsThreshold"
    class="financingAvailable"
  >
    <div>
      <Summary v-if="showSummary" data-cy="summary" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Quote } from "serviceshift-ui/shared/src/typings/quote";
import { watch } from "vue";

import Summary from "./Summary.vue";
import { useFinancingAvailable } from "./useFinancingAvailable";

const { init, totalAmountMeetsThreshold, showFinancingAvailable, showSummary } =
  useFinancingAvailable();

const props = defineProps({
  quote: {
    type: Object as () => Quote,
    required: true
  }
});

watch(
  () => JSON.stringify(props),
  (oldVal, newVal) => {
    if (oldVal === newVal) return;
    init(props);
  },
  { immediate: true }
);
</script>

<style lang="scss">
.financingAvailable {
  font-size: 13px;
}
</style>
