<template>
  <Page title="Profile">
    <div class="d-flex justify-space-between align-baseline full-width">
      <span class="overline">Change Password</span>
      <PasswordForm />
    </div>
    <div class="d-flex justify-space-between align-baseline full-width">
      <span class="overline">Account Info</span>
      <v-tooltip left>
        <template #activator="{ on: tooltip }">
          <v-btn
            text
            icon
            color="secondary"
            @click="toggleModal('profileModal')"
            v-on="{ ...tooltip }"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Account Info</span>
      </v-tooltip>
      <AccountInfoForm
        v-if="profileModal"
        @hide-modal="toggleModal('profileModal')"
        @save-success="handleInfoSaveSuccess"
      />
      <SmallModal
        v-if="missingPhoneModal"
        btn-text="Provide Phone Number"
        @click="handlePhoneAlertClick"
        @hide-modal="toggleModal('missingPhoneModal')"
      >
        <div slot="body">
          Please provide us with your phone number to schedule an appointment.
        </div>
      </SmallModal>
    </div>
    <v-card class="full-width text-center bordered-card">
      <v-card-text>
        <div
          :class="[
            'info-container d-flex align-md-start',
            { 'flex-column': $vuetify.breakpoint.smAndDown }
          ]"
        >
          <ProfileBasicInfo
            :user="user"
            @missing-phone-click="toggleModal('missingPhoneModal')"
          />
        </div>
      </v-card-text>
    </v-card>

    <div v-if="user.children?.length" class="full-width">
      <div class="d-flex justify-space-between align-baseline full-width">
        <div class="d-flex flex-column">
          <span class="overline">Sub Accounts</span>
        </div>
      </div>
      <v-card class="full-width pa-3 bordered-card">
        <v-simple-table>
          <template #default>
            <thead></thead>
            <tbody>
              <tr v-for="child in user.children" :key="child.id">
                <td class="pl-1">
                  {{ child.first_name }} {{  child.last_name }}
                </td>
                <td>
                  {{ getFullAddress(child.primary_address) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>

    <MembershipCard class="mt-2" :memberships="user.memberships" />
    <div
      v-if="paymentMethodsAvailable"
      class="d-flex justify-space-between align-baseline full-width mt-2"
    >
      <span class="overline">Preferences</span>
      <v-tooltip left>
        <template #activator="{ on: tooltip }">
          <v-btn
            text
            icon
            color="secondary"
            v-on="{ ...tooltip }"
            @click="toggleModal('paymentMethodModal')"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Preferences</span>
      </v-tooltip>
      <PreferencesForm
        v-if="paymentMethodModal"
        @hide-modal="toggleModal('paymentMethodModal')"
      />
    </div>
    <v-card
      v-if="paymentMethodsAvailable"
      class="full-width pa-3 bordered-card"
    >
      <v-card-text>
        <v-row class="row caption">
          <v-col cols="5" class="pa-0 px-1">
            <p class="grey--text text--darken-2">Payment Method</p>
          </v-col>
          <v-col cols="7" class="pa-0">
            <p v-if="!user.payment_method">None specified</p>
            <p v-if="user.payment_method">{{ paymentMethod }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <DeletePaymentMethodConfirmation
        v-if="user.payment_method"
        v-model="showDeletePaymentModal"
        class="delete-modal-button"
        show-delete-icon-trigger
        @payment-method-deleted="handlePaymentMethodDeleted"
      />
    </v-card>

    <div class="d-flex justify-space-between align-baseline full-width">
      <div class="d-flex flex-column">
        <span class="overline">Your Addresses</span>
        <span class="caption italic grey--text text--darken-2"
          >Select row to edit address</span
        >
      </div>
      <v-btn
        v-if="addresses.length"
        text
        icon
        color="secondary"
        @click="toggleModal('addressModal')"
      >
        <v-icon>mdi-plus-box-outline</v-icon>
      </v-btn>

      <AddressForm
        v-if="addressModal"
        :key="addressKey"
        :current-address="currentAddress"
        @hide-modal="handleReset"
        @save-success="handleAddressSaveSuccess"
      />
    </div>
    <v-card class="full-width pa-3 bordered-card">
      <v-simple-table v-if="addresses.length">
        <template #default>
          <thead></thead>
          <tbody>
            <tr
              v-for="address in addresses"
              :key="address.id"
              @click="editAddress(address)"
            >
              <td style="padding: 0">
                <div v-if="address.primary" class="d-flex justify-center">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon small color="primary" v-on="on">mdi-star</v-icon>
                    </template>
                    <span>Primary Address</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="pl-1">
                <div class="d-flex flex-column">
                  {{ getAddressDisplayName(address) }}
                  <div class="d-flex" :class="{ 'red--text': address.invalid }">
                    <v-icon
                      v-if="address.invalid"
                      color="red"
                      class="mr-1 error-icon"
                      small
                      >mdi-alert-circle</v-icon
                    >
                    {{ getFullAddress(address) }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <ProvideAddress v-else @provide-address="toggleModal('addressModal')" />
    </v-card>
    <EquipmentTable />
    <SuccessToast v-if="accountCreateSuccess">
      <div slot="body">
        <p class="headline">Thank You!</p>
        <p class="mb-2">Your account has been created successfully.</p>
        <p>
          We have {{ $store.state.tenant.name }} technicians ready to help you,
          whenever you need us!
        </p>
      </div>
    </SuccessToast>
    <SuccessToast v-if="correctPhoneSuccess">
      <div slot="body">
        <div>Thank you for adding your phone number.</div>
        <div>You can now schedule an appointment.</div>
      </div>
    </SuccessToast>
    <SuccessToast v-if="correctAddressSuccess">
      <div slot="body">
        <div>Thank you for correcting your address.</div>
      </div>
    </SuccessToast>
    <SuccessToast v-if="addAddressSuccess">
      <div slot="body">
        <div>Thank you for adding your address.</div>
      </div>
    </SuccessToast>
  </Page>
</template>

<script>
import { mapState } from "vuex";
import { formatFullAddress, getAddressName } from "@/lib/address.js";

import AccountInfoForm from "@/components/AccountModal/AccountInfoForm.vue";
import AddressForm from "@/components/AddressForm.vue";
import Page from "@/components/Page.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import PreferencesForm from "@/components/PreferencesForm.vue";
import ProfileBasicInfo from "@/components/ProfileBasicInfo.vue";
import DeletePaymentMethodConfirmation from "@/modules/paymentMethods/components/modals/DeletePaymentMethodConfirmation.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";
import SmallModal from "../components/SmallModal.vue";
import SuccessToast from "../components/SuccessToast.vue";
import EquipmentTable from "./EquipmentTable.vue";
import MembershipCard from "./MembershipCard.vue";
import ProvideAddress from "./ProvideAddress.vue";

export default {
  components: {
    AccountInfoForm,
    AddressForm,
    DeletePaymentMethodConfirmation,
    EquipmentTable,
    Page,
    PasswordForm,
    PreferencesForm,
    ProfileBasicInfo,
    SmallModal,
    SuccessToast,
    ProvideAddress,
    MembershipCard
  },
  setup() {
    const { paymentMethods, paymentMethodsAvailable } = usePaymentMethods();
    return {
      paymentMethods,
      paymentMethodsAvailable
    };
  },
  data() {
    return {
      showDeletePaymentModal: false,
      accountCreateSuccess: false,
      currentAddress: null,
      snackbar: false,
      addressKey: Date.now().toString(),
      profileModal: false,
      addressModal: false,
      paymentMethodModal: false,
      missingPhoneModal: false,
      correctPhoneSuccess: false,
      correctAddressSuccess: false,
      addAddressSuccess: false
    };
  },
  computed: {
    ...mapState({
      user: "user"
    }),
    addresses() {
      return this.user.addresses.slice().sort((a, b) => {
        return a.primary > b.primary ? -1 : 1;
      });
    },
    paymentMethod() {
      const { brand, last4, ach_account_type, payment_type } =
        this.user.payment_method;
      if (payment_type === "card") {
        return `${brand} ...${last4}`;
      }
      if (payment_type === "ach") {
        return `${ach_account_type} ACH ...${last4}`;
      }
      return "";
    }
  },
  watch: {
    addressModal(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.currentAddress = null;
      }
    }
  },
  mounted() {
    if (this.$route.query.created) {
      this.accountCreateSuccess = true;
    }
    console.log(this.user);
  },
  methods: {
    handlePaymentMethodDeleted() {
      this.$store.dispatch("fetchUser");
    },
    getFullAddress(address) {
      return formatFullAddress(address);
    },
    editAddress(address) {
      this.toggleModal("addressModal");
      this.currentAddress = address;
    },
    getAddressDisplayName(address) {
      return getAddressName(address);
    },
    handleReset() {
      this.toggleModal("addressModal");
      this.addressKey = new Date().toString();
    },
    toggleModal(modal) {
      this[modal] = !this[modal];
    },
    handlePhoneAlertClick() {
      this.toggleModal("missingPhoneModal");
      this.toggleModal("profileModal");
    },
    handleInfoSaveSuccess(correctedValues) {
      if (correctedValues) {
        this.correctPhoneSuccess = true;
      }
    },
    handleAddressSaveSuccess(correctedValues) {
      this.correctAddressSuccess = correctedValues;
      this.addAddressSuccess = !correctedValues;
    }
  }
};
</script>

<style lang="scss" scoped>
table tr td {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.54);
}

.info-container {
  white-space: nowrap;
  justify-content: space-between;
}

.delete-modal-button {
  position: absolute;
}

.primary-text {
  font-weight: bold;
}

.divider {
  height: 1px;
  width: 100%;
  background: #ddd;
  margin-top: 8px;
  margin-bottom: 8px;
}

.error-icon {
  font-size: 18px !important;
  margin-bottom: 3px;
}
</style>
