<template>
  <div>
    <Page title="All Invoices" max-width="850">
      <UnpaidInvoices @pay-balance="handlePayBalance" />
      <Datatable
        ref="datatable"
        :headers="headers"
        :report-path="'/reports/consumer/invoices.json'"
        :sort-by="'id'"
        :sort-desc="true"
      >
        <template #item.view="{ item }">
          <router-link :to="`/invoice/${item.id}`">View Invoice</router-link>
        </template>
        <template v-if="paymentMethodsAvailable" #item.pay="{ item }">
          <v-btn
            v-if="item.balance !== '$0.00'"
            color="primary"
            @click="handlePayBalance(item.id)"
          >
            Pay Balance Due
          </v-btn>
        </template>
      </Datatable>
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      :customer="user"
      :invoice-id="selectedInvoiceId"
    />
  </div>
</template>

<script setup lang="ts">
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { computed, onMounted, ref } from "vue";
import { useStore } from "@/lib/vuex-composition";

import Datatable from "@/components/Datatable.vue";
import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Page from "@/components/Page.vue";
import UnpaidInvoices from "@/components/UnpaidInvoices.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

const { paymentMethodsAvailable, definePaymentCallbacks } = usePaymentMethods();
const store = useStore();

const selectedInvoiceId = ref<number>(0);
const showPaymentModal = ref(false);
const datatable = ref<any | null>(null);

const user = computed<Customer | undefined>(
  () => store.state.user || undefined
);
const headers = computed(() => {
  if (!store.state.user) return [];
  const headers = [
    { text: "Date", value: "date" },
    { text: "Invoice #", value: "id" },
    { text: "Job #", value: "job_id" },
    { text: "Total", value: "total", align: "end" },
    { text: "Balance Due", value: "balance", align: "end" },
    { text: "View", value: "view", sortable: false },
    { text: "", value: "pay", sortable: false }
  ];
  if (store.state.user.children?.length) {
    headers.unshift({ text: "Account", value: "customer_name" });
  }
  return headers;
});

onMounted(() => {
  definePaymentCallbacks({
    onPaymentSuccess: refreshData
  });
});

const refreshData = () => {
  datatable.value.fetchData();
};
const handlePayBalance = (invoiceId: number) => {
  selectedInvoiceId.value = invoiceId;
  showPaymentModal.value = true;
};
</script>

<style lang="scss"></style>
