<template>
  <div v-if="!loading">
    <component :is="layoutToUse" v-if="canSeeLayout">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </component>
    <div v-if="tenantError" class="error-page">
      <v-icon color="error">mdi-alert-circle</v-icon>
      <p>It appears you are using an invalid URL.</p>
      <p>Please check the URL and try again.</p>
    </div>
    <div v-if="subAccountError" class="error-page">
      <v-icon color="error">mdi-alert-circle</v-icon>
      <p>You do not have permission to access this page.</p>
    </div>
    <error-toast v-if="apiErrorMessage" @hide-modal="apiErrorMessage = null"
      ><div slot="body">{{ apiErrorMessage }}</div></error-toast
    >
    <error-toast
      v-if="globalErrorMessage"
      @hide-modal="setGlobalErrorMessage(null)"
      ><div slot="body">{{ globalErrorMessage }}</div></error-toast
    >
  </div>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { mapActions, mapState } from "vuex";

import DefaultLayout from "@/components/Layouts/DefaultLayout.vue";
import "./lib/sentry";
import ErrorToast from "./components/ErrorToast.vue";
import {
  apiConfig,
  setErrorInterceptor,
  setRequestHeaders,
  setTenantInterceptor
} from "./lib/api";
import { AuthLocalStorage, isLoggedInWithRole } from "./lib/auth";
import AuthMixin from "./mixins/auth";

export default {
  name: "App",
  components: {
    ErrorToast
  },
  mixins: [AuthMixin],
  data() {
    return {
      loading: true,
      tenantError: false,
      apiErrorMessage: null,
      subAccountError: false
    };
  },
  computed: {
    ...mapState(["globalErrorMessage"]),
    layoutToUse() {
      return this.$route.meta.layout || DefaultLayout;
    },
    canSeeLayout() {
      return (
        !this.loading &&
        this.tenant &&
        !this.tenantError &&
        !this.subAccountError
      );
    },
    user() {
      return this.$store.state.user;
    },
    tenant() {
      return this.$store.state.tenant;
    }
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Check if the user has a parent account.
        // if so, show the access denied error
        this.subAccountError = !!newValue.parent;
      }
    }
  },
  async created() {
    await this.loadTenant();
    this.autoLogin();
    setErrorInterceptor(this.handleAPIError);
    this.setupGA();
    this.addCustomerGA();
  },
  methods: {
    ...mapActions(["setGlobalErrorMessage"]),
    async autoLogin() {
      this.loading = true;
      const { email, token, role } = AuthLocalStorage;
      if (role === AuthRole.customer && email && token) {
        setRequestHeaders({
          ...AuthLocalStorage.values(),
          authentication_token: AuthLocalStorage.token
        });
        // NOTE: The current thinking is that the token should always be able to pull info about the
        // user regardless of what role. If this ever changes, we need to update this logic.
        // TODO: FindByEmail needs to return a role
        try {
          const res = await API.user.findByEmail(email);
          await this.$store.dispatch("setUser", {
            ...res.data,
            role: AuthRole.customer
          });
          if (!isLoggedInWithRole(AuthRole.customer)) {
            if (this.$route.path === "/login") {
              await this.$router.push({ name: "dashboard" });
            }
          }
        } catch (e) {
          this.logout();
        }
      }
      this.loading = false;
    },
    handleAPIError(error) {
      if (apiConfig.ignoreToastNotificationOnError) {
        return;
      }
      if (error.response) {
        if (error.response.status === 401) {
          // Slight delay so UI has a chance to
          // display error message before redirect
          AuthLocalStorage.clear();
          this.$store.dispatch("setUser", null);
          this.$router.push({
            path: "/login",
            query: { redirect: this.$route.fullPath }
          });
          return Promise.reject(error);
        }
        this.apiErrorMessage =
          error.response.data.message ||
          "An unexpected error occurred, please try again.";
      }
      return Promise.reject(error);
    },
    loadTenant() {
      const subdomain = window.location.host.split(".")[0];
      return this.fetchTenant(subdomain)
        .then(async (res) => {
          setTenantInterceptor(res.data.subdomain);
          const customizations = await this.fetchCustomizations(subdomain);
          this.tenantError = false;
          // Update vuetify theme colors
          this.$vuetify.theme.themes.light.primary =
            customizations.Colors.primary_color;
          this.$vuetify.theme.themes.light.secondary =
            customizations.Colors.secondary_color;
          // Update site title
          document.title = `${res.data.name} Portal`;
          // Set custom favicon
          if (customizations.Assets.favicon_url) {
            this.updateFavicon(customizations.Assets.favicon_url);
          }
          // Override logo url
          customizations.Assets.logo_url = customizations.Assets.light_logo_url;
          // Store tenant and customizations in store
          this.$store.commit("replaceState", {
            state: "tenant",
            data: {
              ...res.data,
              customizations
            }
          });
        })
        .catch((e) => {
          this.tenantError = true;
          console.error(e);
        });

      // const data = {
      //   id: "1",
      //   name: "billyGO",
      //   phone: "(817) 722-6151",
      //   links: {
      //     logo:
      //       "https://chase-shift-dev.s3.amazonaws.com/portal/billygo/logo.svg",
      //     favicon:
      //       "https://www.billygo.com/wp-content/themes/billygo/favicon.png",
      //     pinIcon:
      //       "https://chase-shift-dev.s3.amazonaws.com/portal/billygo/truck.png",
      //     home: "https://billygo.com",
      //     contact: "https://www.billygo.com/contact/"
      //   },
      //   colors: {
      //     primary: "#EF5F28",
      //     secondary: "#0A4875"
      //   }
      // };

      // const data = {
      //   id: "2",
      //   name: "ServiceSHIFT",
      //   phone: "(555) 555-5555",
      //   links: {
      //     logo:
      //       "https://chase-shift-dev.s3.amazonaws.com/portal/serviceshift/shift_logo.png",
      //     home: "https://serviceshift.com",
      //     contact: "https://serviceshift.com"
      //   },
      //   colors: {
      //     primary: "#2EB24B",
      //     secondary: "#424242"
      //   }
      // };

      // const data = {
      //   id: "3",
      //   name: "Milestone",
      //   phone: "(555) 555-5555",
      //   links: {
      //     logo: "https://www.milestoneelectricdfw.com/images/logo.png",
      //     home: "https://www.milestoneelectricdfw.com/",
      //     contact: "https://www.milestoneelectricdfw.com/"
      //   },
      //   colors: {
      //     primary: "#028CFF",
      //     secondary: "#FF0200"
      //   }
      // };

      // const data = {
      //   id: "4",
      //   name: "TLC Electrical",
      //   phone: "(555) 555-5555",
      //   links: {
      //     logo:
      //       "https://www.tlcelectrical.com/wp-content/themes/Paradox/assets/images/desktop-logo.png",
      //     home: "https://www.tlcelectrical.com/",
      //     contact: "https://www.tlcelectrical.com/"
      //   },
      //   colors: {
      //     secondary: "#0707CC",
      //     primary: "#FFB301"
      //   }
      // };
    },
    fetchTenant(subdomain) {
      return API.tenant.getBySubdomain(subdomain);
    },
    fetchCustomizations(subdomain) {
      return API.tenant.getCustomizations(subdomain).then((res) => {
        this.$store.commit("replaceState", {
          state: "customizations",
          data: res.data
        });
        return Object.keys(res.data).reduce((customizations, category) => {
          if (!customizations[category]) {
            customizations[category] = {};
          }
          Object.keys(res.data[category]).forEach((key) => {
            customizations[category][key] =
              res.data[category][key].value || res.data[category][key].default;
          });
          return customizations;
        }, {});
      });
    },
    setupGA() {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", import.meta.VITE_GOOGLE_ANALYTICS_ID, {
        app_name: "Consumer Portal",
        debug_mode: import.meta.VITE_ENV !== "production"
      });
    },
    addCustomerGA() {
      if (!this.tenant.customizations?.Analytics?.google_analytics_id) return;
      if (
        this.tenant.customizations.Analytics.google_analytics_id ===
        import.meta.VITE_GOOGLE_ANALYTICS_ID
      )
        return;
      const id = this.tenant.customizations.Analytics.google_analytics_id;
      const head = document.getElementsByTagName("head")[0];
      const gaScript = document.createElement("script");
      gaScript.setAttribute(
        "src",
        `https://www.googletagmanager.com/gtag/js?id=${id}`
      );
      gaScript.onload = function () {
        window.gtag("js", new Date());
        window.gtag("config", `${id}`, {
          debug_mode: import.meta.VITE_ENV !== "production"
        });
      };
      head.insertBefore(gaScript, head.children[1]);
    },
    updateFavicon(url) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }
};
</script>

<style lang="scss">
@import "./styles/vuetify-overrides.scss";
@import "./styles/helpers.scss";
.error-page {
  padding: 20% 10%;
  text-align: center;
  font-family: "Roboto";
  background: #333;
  color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  i {
    margin-right: 5px;
    color: #ff5252 !important;
    font-size: 4em !important;
  }
}
</style>
