import { Address } from "./address";
import { AuthRole, AuthStrategy } from "./auth";
import { CustomerMembership } from "./memberships";
import { PaymentMethod } from "./paymentMethods";

export class Customer {
  auth_strategy = AuthStrategy.password;
  role?: AuthRole = undefined;
  id = -1;
  first_name = "";
  last_name = "";
  full_name = "";
  email = "";
  phone = "";
  contact_method = "";
  authentication_token = "";
  fcm_token = "";
  archived?: boolean;
  customer_id?: number;
  membership: CustomerMembership | undefined;
  memberships: CustomerMembership[] | null | undefined;
  emails: CustomerEmailsEntity[] | null | undefined;
  phones: CustomerPhonesEntity[] | null | undefined;
  payment_method: null | PaymentMethod | undefined;
  payment_method_create_url?: string = undefined;
  addresses: Address[] | undefined;
  primary_address?: Address;
  accounting_key: string | undefined;
  links?: {
    customer_archive?: string;
    customer_unarchive: string;
    quote_create: string;
    job_create: string;
    link_call: string;
    payment_method_create: string;
    fetch_equipment: string;
    equipment_create: string;
    set_accounting_key?: string;
    fetch_shared_accounting?: string;
  } = undefined;
  children: Customer[] | undefined;

  constructor(params: Partial<Customer> = {}) {
    Object.assign(this, params);
  }
}

export class CustomerEmailsEntity {
  id = -1;
  email_address = "";
  primary = false;
  label = "";

  constructor(params: Partial<CustomerEmailsEntity> = {}) {
    Object.assign(this, params);
  }
}

export class CustomerPhonesEntity {
  id = -1;
  phone_number = "";
  type = "";
  primary = false;
  label = "";

  constructor(params: Partial<CustomerPhonesEntity> = {}) {
    Object.assign(this, params);
  }
}

export class CustomerShort {
  authentication_token = "";
  cable_token = "";
  customer_id = -1;
  email = "";
  full_name = "";
  phone = "";
  type = "";

  constructor(params: Partial<CustomerShort> = {}) {
    Object.assign(this, params);
  }
}
