<template>
  <div v-if="customerEquipment.length" class="full-width">
    <div class="d-flex justify-space-between align-baseline full-width">
      <div class="d-flex flex-column">
        <span class="overline mt-2">Equipment</span>
      </div>
    </div>
    <v-card class="card-container full-width section-spacing-10 bordered-card">
      <EquipmentCard
        v-for="equipment in customerEquipment"
        :key="equipment.id"
        :equipment-type-name="equipment.equipment_type_name"
        v-bind="equipment"
        :service-history="equipment.service_history"
        default-collapsed
        class="equipment-card"
      >
      </EquipmentCard>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { addressLabel } from "@/lib/address";

export default {
  computed: {
    ...mapState({
      user: "user",
      customerEquipment({ equipment }) {
        return equipment
          .filter((equipment) => !equipment.archived)
          .map((equipment) => ({
            ...equipment,
            address: addressLabel(equipment.address)
          }));
      }
    })
  },
  mounted() {
    this.fetchCustomerEquipment();
  },
  methods: {
    ...mapActions(["fetchCustomerEquipment"])
  }
};
</script>

<style lang="scss">
.card-container {
  padding: 7.5px;
}
.equipment-card {
  font-size: 12px;
  border-radius: 0px !important;
}
.collapse-button .fa-angle-up {
  margin-right: 0 !important;
}
.serviceHistoryTable__row__value {
  text-align: right;
  max-width: 75%;
}
.serviceHistoryTable__row__field {
  white-space: nowrap;
}
.equipmentCard h5 {
  font-size: 1.2em;
}
.equipment-card {
  .card-header,
  .card-body {
    width: 100%;
  }
  .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
