<template>
  <div>
    <Loader v-if="fetchingQuote" />
    <Page v-else-if="quote" :title="title">
      <div class="full-width">
        <v-alert v-if="$vuetify.breakpoint.xsOnly" type="warning" dense>
          Quote best viewed in Landscape mode. Rotate your device for the best
          experience.
        </v-alert>
        <div v-if="quote.links.quote_proceed" class="mb-6 d-flex align-center">
          <div>
            <router-link :to="{ name: 'quotes' }">
              <i class="mr-3 fa fa-arrow-left"></i>
              <span>Return to quotes</span>
            </router-link>
          </div>
          <v-btn
            class="ml-auto"
            type="submit"
            color="primary"
            :loading="loading"
            @click="toggleProceedModal"
            >Proceed with Service</v-btn
          >
        </div>
        <span class="overline text-left full-width">Quote</span>
        <v-card
          class="full-width bordered-card mb-4 pa-3"
          style="font-size: 0.75rem !important; overflow-y: scroll"
        >
          <Quote
            :quote="quote"
            :customizations="customizations"
            tech-mode
            :class="{ 'hidden-signature': hideSignature }"
          >
            <template
              #financingOptions
            >
              <FinancingAvailable
                :quote="quote"
              />
            </template>
          </Quote>
          <div v-if="quote.links.quote_proceed" class="text-center my-6">
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
              @click="toggleProceedModal"
              >Proceed with Service</v-btn
            >
          </div>
        </v-card>
        <quote-proceed-modal
          v-if="proceedModal"
          :quote="quote"
          @hide-modal="toggleProceedModal"
          @save-success="toggleProceedSuccess"
        ></quote-proceed-modal>
        <success-toast v-if="proceedSuccess" @hide-modal="toggleProceedSuccess">
          <div slot="body">
            <p>Your request for service has been successfully submitted.</p>
          </div>
        </success-toast>
      </div>
    </Page>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Loader from "@/components/Loader.vue";
import Page from "@/components/Page.vue";
import QuoteProceedModal from "@/components/QuoteProceedModal.vue";
import SuccessToast from "@/components/SuccessToast.vue";
import FinancingAvailable from "@/modules/financing/financingAvailable/FinancingAvailable.vue";

export default {
  components: {
    Loader,
    FinancingAvailable,
    Page,
    QuoteProceedModal,
    SuccessToast
  },
  data() {
    return {
      fetchingQuote: true,
      loading: false,
      proceedModal: false,
      proceedSuccess: false
    };
  },
  computed: {
    ...mapState({
      quote: "currentQuote",
      customizations: ({ customizations, tenant }) => {
        return { ...customizations, Tenant: tenant };
      },
      hideSignature() {
        return ["pending", "draft"].includes(this.quote.status);
      }
    }),
    title() {
      return `Quote #${this.$route.params.id} Summary`;
    }
  },
  mounted() {
    this.loadQuote();
  },
  methods: {
    ...mapActions(["fetchQuote"]),
    async loadQuote() {
      this.fetchingQuote = true;
      try {
        await this.fetchQuote(this.$route.params.id);
      } catch (e) {
        console.error(e);
      }
      this.fetchingQuote = false;
    },
    toggleProceedModal() {
      this.proceedModal = !this.proceedModal;
    },
    toggleProceedSuccess() {
      this.proceedSuccess = !this.proceedSuccess;
    }
  }
};
</script>

<style lang="scss">
.hidden-signature .signature.signature {
  display: none !important;
}
.quote-body {
  min-width: 415px;

  .summary {
    margin-top: 1rem;
  }
}
</style>
